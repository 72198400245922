.web3modal-modal-lightbox {z-index: 10000 !important;}

input[type=text]{
  color: #000;
}

#walletConnectForm .hidden {
  display: none !important;
}

.select-chain {
  width: 50%;
}
.select-chain button,
.select-chain {
  border-radius: 0;
}

.select-chain.border{
  background-color: #fff !important;
}

.select-chain:first-of-type button,
.select-chain:first-of-type{
  border-radius: 50px 0 0 50px;
}
.select-chain:last-of-type button,
.select-chain:last-of-type{
  border-radius: 0 50px 50px 0;
}